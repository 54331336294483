
.form-check-input:checked {
    background-color: #0149AD !important;
    border-color: #0149AD !important;
}
.form-switch .form-check-input {
    width: 2.5em !important;
}

.nav-link {
    display: block;
    padding: 0.5rem 1rem;
    color: #0149AD;
    text-decoration: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}