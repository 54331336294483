.accordion-button:not(.collapsed) {
    color: #0149AD !important;
    font-weight: bold;
    background-color: white !important;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

.accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1rem 1.25rem;
    font-size: 1rem;
    color: #1e2a35;
    text-align: left;
    background-color: white ;
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
}
.header-color{
    background-color: #23528b;
}
.subheader-color{
    background-color: #dce0e7;
}