.nav-res{
    margin-top:5%;
}
.img-user{
    width: 40px;
}
.user-text{
    font-size: 16px;
}
.res-sidebar {
    position: fixed;
    left: 0;
    right: 0;
    font-size: 16px;
     top: 5%;
}
.setting{
  margin-top: 5%;
}


     @media only screen and (max-width: 991px){
  .res-sidebar {
  position: relative;

}

    }
@media only screen and (max-width: 877px){
.nav-res{
    margin-top:7%;
}
    }

    @media only screen and (max-width: 649px){
.nav-res{
    margin-top:10%;
}
    }
        @media only screen and (max-width: 649px){
.nav-res{
    margin-top:15%;
}
    }

      @media only screen and (max-width: 374px){
.img-user{
    width: 20px;
}

    }
       @media only screen and (max-width: 354px){
.img-user{
    width: 20px;
}
.user-text{
    font-size: 14px;
}
    }

     @media only screen and (max-width: 316px){
        .nav-res{
    margin-top:30%;
}

    }