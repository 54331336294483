.bg-interactive{
    background: linear-gradient(to bottom left,#216e93,#21648a,#1f5881,#172d5b,#191c51);
}
.current-time-block{
    background:hsla(0,0%,100%,.13); 
    width: 30%;
    padding:10px 12px 10px 12px;
    border-radius: 50px;
  
}
.apexcharts-tooltip span {
    color: #ffffff;
}
.apexcharts-legend-text{
    color:#ffffff !important;
}
.apexcharts-datalabel-value{
fill:#ffffff;
}


@media screen and (max-width: 990px) {
.current-time-block{
    background:hsla(0,0%,100%,.13); 
    width: 78%;
      padding:10px 10px 10px 10px;
    border-radius: 50px;
}
}